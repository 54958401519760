<mat-form-field>
  <mat-select [(ngModel)]="selectedValue" (selectionChange)="selectionChange($event)">
   <mat-option *ngFor="let lang of translate.getLangs()" [value]="lang"> 
      <ng-container *ngFor="let customName of langArray">
         <ng-container *ngIf="customName.value == lang">
            {{customName.name}}
         </ng-container>
      </ng-container>
   </mat-option>
  </mat-select>
</mat-form-field>