import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { global } from "../Config/config";

@Injectable()
export class OrderService {
    public url: string;

    constructor(private _http: HttpClient) {
        this.url = global.url;
    }

    createOrder(params): Observable<any> {
        //Definir cabeceras
        let headers = new HttpHeaders().set(
            "Content-Type",
            "application/json; charset=utf-8"
        );

        // Hacer peticion ajax
        return this._http.post(this.url + "create-order/", params, { headers: headers });
    }

    createOrderInDrive(params): Observable<any> {
        //Definir cabeceras
        let headers = new HttpHeaders().set(
            "Content-Type",
            "application/json; charset=utf-8"
        );

        // Hacer peticion ajax
        return this._http.post(this.url + "create-order-in-drive/", params, { headers: headers });
    }

    getOrder(id): Observable<any> {
        //Definir cabeceras
        let headers = new HttpHeaders().set(
            "Content-Type",
            "application/json; charset=utf-8"
        );

        // Hacer peticion ajax
        return this._http.get(this.url + "get-order/" + id, { headers: headers });
    }

    getAllOrders(): Observable<any> {
        //Definir cabeceras
        let headers = new HttpHeaders().set(
            "Content-Type",
            "application/json; charset=utf-8"
        );

        // Hacer peticion ajax
        return this._http.get(this.url + "get-orders/", { headers: headers });
    }

    updateOrder(id): Observable<any> {
        //Definir cabeceras
        let headers = new HttpHeaders().set(
            "Content-Type",
            "application/json; charset=utf-8"
        );

        // Hacer peticion ajax
        return this._http.put(this.url + "update-order/" + id, { headers: headers });
    }

}
