<div class="container">
    <mat-horizontal-stepper [linear]="isLinear" #stepper class="mt-4 mb-4">
        <mat-step [stepControl]="firstFormGroup">
            <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel>Datos de contacto: </ng-template>
                <form [formGroup]="order_form">
                    <div class="example-container">
                        <mat-form-field class="w-100 mb-3">
                            <input matInput placeholder="A nombre de:" formControlName="name">
                            <mat-error
                                *ngIf="order_form.get('name').hasError('required') && order_form.get('name').touched">
                                Este
                                campo
                                no puede estar vacio.</mat-error>
                            <mat-error *ngIf="order_form.get('name').hasError('maxlength')"> No se permiten mas de 64
                                caracteres.
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="w-100 mb-3">
                            <input matInput placeholder="Email" formControlName="email">
                            <mat-error
                                *ngIf="order_form.get('email').hasError('required') && order_form.get('email').touched">
                                Este
                                campo
                                no puede estar vacio.</mat-error>
                            <mat-error *ngIf="order_form.get('email').hasError('maxlength')"> No se permiten mas de 64
                                caracteres.
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="w-100 mb-3">
                            <input matInput placeholder="Teléfono con lada del país" formControlName="fullphone">
                            <mat-error
                                *ngIf="order_form.get('fullphone').hasError('required') && order_form.get('fullphone').touched">
                                Este
                                campo
                                no puede estar vacio.</mat-error>
                            <mat-error *ngIf="order_form.get('fullphone').hasError('maxlength')"> No se permiten mas de
                                64
                                caracteres.
                            </mat-error>
                        </mat-form-field>


                    </div>
                </form>
                <div>
                    <button mat-button matStepperNext (click)="validateFirstForm()"> Confirmar 🢩 </button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" label="Datos en el sistema">


            <form [formGroup]="secondFormGroup">


                <div fxLayout="row wrap" class="col-gap" align='center'>
                    <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">
                        <form class="m-2">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Seleccionar tu Institución: </mat-label>
                                <input matInput type="text" placeholder="Busca la institución por su nombre."
                                    aria-label="Parent" [matAutocomplete]="autoParent" [formControl]="parentCtrl"/>
                            </mat-form-field>
                            <mat-autocomplete #autoParent="matAutocomplete">
                                <mat-option *ngFor="let option of filters_parents_array | async" [value]="option.nombre"
                                    (onSelectionChange)="getParentOption(option.nombre, option.iddominio)">
                                    {{ option.iddominio }} |
                                    <small>{{option.nombre}}</small>
                                </mat-option>
                            </mat-autocomplete>
                        </form>

                    </div>
                </div>


                <div>
                    <button mat-button matStepperPrevious>🢨 Atras </button>
                    <button mat-button matStepperNext (click)="validateSecondForm()">Siguiente 🢩</button>
                </div>
            </form>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Selección de libro y cantidad.</ng-template>
            <form class="m-2">

                <div fxLayout="row wrap" class="col-gap" align='center'>
                    <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="80" fxFlex.lg="80" fxFlex.xl="20">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Filtrar por producto</mat-label>
                            <input matInput placeholder="Busca el producto por su nombre, isbn o nivel."
                                aria-label="Producto" [matAutocomplete]="auto" [formControl]="articleCtrl" />
                        </mat-form-field>
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of articles_array | async" [value]="option.nombre"
                                (onSelectionChange)="getProductData(option)">
                                {{option.nombre}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>

                    <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="20" fxFlex.lg="20" fxFlex.xl="20">
                        <form [formGroup]="order_form">
                            <mat-form-field class="w-100 mb-3">
                                <input matInput placeholder="Cantidad:" formControlName="code_number">
                                <mat-error
                                    *ngIf="order_form.get('code_number').hasError('required') && order_form.get('code_number').touched">
                                    Este
                                    campo
                                    no puede estar vacio.</mat-error>
                                <mat-error *ngIf="order_form.get('code_number').hasError('maxlength')"> No se permiten
                                    mas
                                    de 64
                                    caracteres.
                                </mat-error>
                            </mat-form-field>
                        </form>
                    </div>


                </div>

                <div *ngIf="order?.image && order?.virt_image">
                    <ul>
                        <p>
                            {{order?.virt_article_name}}
                        </p>
                        <p>
                            Serie: {{order?.serie}}
                        </p>
                        <p>
                            Formato: {{order?.formato}}
                        </p>
                        <p>
                            Nivel: {{order?.nivel}}
                        </p>
                        <p>
                            Versión: {{order?.version}}
                        </p>
                    </ul>
                    <div fxLayout="row wrap" class="col-gap">
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50" align='center'>
                            <p>Libro: </p>
                            <img src={{order?.image}} style="width: 50%;">
                        </div>
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50" align='center'>
                            <p>Código: </p>
                            <img src={{order?.virt_image}} style="width: 50%;">
                        </div>
                    </div>
                </div>

            </form>


            <div>
                <button mat-button matStepperPrevious> 🢨 Atras</button>
                <button style="background-color: darkcyan; color: white;" mat-button
                    (click)="createOrder(); stepper.reset();">Crear nueva orden ✔️</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>