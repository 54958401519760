<footer>
  <div class="footer-v1 custom-footer">
    <div class="container">
      <div class="footer-top-v1"></div>
      <!-- <hr> -->
      <div class="footer-bottom-v1">
        <div fxLayout="row wrap" class="col-gap">
          <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
            <img src="assets/images/cards.png" width="218" height="18" alt="Credit Cards" />
            <div>
              <a href="https://www.iubenda.com/privacy-policy/15194258" class="iubenda-white iubenda-embed"
                title="Privacy Policy" target='blank'>Privacy Policy</a>

            </div>
          </div>
          <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50" class="text-right">
            Links2Shop &copy; All Rights Reserved | Developed and powered by
            <img src="{{ footer }}" width="100" height="34" alt="" />
          </div>
        </div>
        <div>
          <iframe data-aa="1787606" src="//acceptable.a-ads.com/1787606" style="border:0px; padding:0; width:100%; height:100%; overflow:hidden; background-color: transparent;" ></iframe>
        </div>
      </div>
    </div>
  </div>
</footer>