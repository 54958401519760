<form [formGroup]="addNewUserForm" class="popup-card-width add-new-user">
	<div>
		<h2 mat-dialog-title>Add New User</h2>
	</div>	
	<mat-dialog-content class="mb-4">
		<div>
			<div>
				<mat-form-field class="w-100">
					<input matInput type="text" formControlName="name" class="form-control" placeholder="Name">
					<mat-error *ngIf="addNewUserForm.controls['name'].touched && !addNewUserForm.controls['name'].valid">
						<mat-error *ngIf="addNewUserForm.controls['name'].hasError('required')" class="required alert-error">  
							You must include a Name.
						</mat-error>
		         </mat-error>
				</mat-form-field>
            <mat-form-field class="w-100">
               <input matInput type="text" formControlName="email" class="form-control" placeholder="Email">
               <mat-error *ngIf="addNewUserForm.controls['email'].touched && !addNewUserForm.controls['email'].valid">
                  <mat-error *ngIf="addNewUserForm.controls['email'].hasError('required')" class="required alert-error">  
                     You must include a E-mail Address.
                  </mat-error>  
                  <mat-error *ngIf="addNewUserForm.controls['email'].hasError('pattern')">
                     E-mail must be valid
                  </mat-error>
               </mat-error>
            </mat-form-field> 
            <mat-form-field class="w-100">
               <select matNativeControl formControlName="accessType" placeholder="Access Type">
                  <option value="Admin">Admin</option>
						<option value="Write">Write</option>
						<option value="Read">Read</option>
					</select>	
					<mat-error *ngIf="addNewUserForm.controls['accessType'].touched && !addNewUserForm.controls['accessType'].valid">
						<mat-error *ngIf="addNewUserForm.controls['accessType'].hasError('required')" class="required alert-error">
                     You must Select a Access Type.
                  </mat-error>  
               </mat-error>
				</mat-form-field>
			</div>
      </div>
	</mat-dialog-content>
	<mat-dialog-actions class="m-0">
		<button type="button" mat-raised-button (click)="dialogRef.close()" color="primary">CLOSE</button>
		<button (click)="onFormSubmit()" type="submit" [disabled]="!addNewUserForm.valid" mat-raised-button color="warn">SUBMIT</button>
	</mat-dialog-actions>
</form>
<!-- add-new-user -->