<div class="container">
    <div align='center'>
        <br>
        <h4> Petición de apertura de tienda(s) {{ bulkData?.date }}</h4>

    </div>
    <br>
    <mat-divider></mat-divider>
    <br>
    <div align='right'>
        <h5> Escuelas </h5>
    </div>
    <mat-card>
        <div class="table-responsive-x">
            <table class="w-100 table-scroll-wrap animated fadeIn" mat-table [dataSource]="shopsDataSource" matSort>
                <ng-container matColumnDef="temporal_id">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Id Temporal
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.temporal_id }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="nombre">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Nombre
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.nombre }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="alias">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Alias
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.alias }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="parent">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Parent
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.parent }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="calle">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Calle
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.calle }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="campus">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Campus
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.campus }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="pais">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Pais
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.pais }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="ciudad">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Ciudad
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.ciudad }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="estado">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Estado
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.estado }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="colonia">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Colonia
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.colonia }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="edificio">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Edificio
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.edificio }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="codigo_postal">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Codigo postal
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.codigo_postal }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="num_ext">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Numero Exterior
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.num_ext }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="num_int">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Numero Interior
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.num_int }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="telefono">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Telefono
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.telefono }}</div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedShopsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedShopsColumns"></tr>
            </table>
        </div>
    </mat-card>
    <br>
    <mat-divider></mat-divider>
    <br>
    <div align='right'>
        <h5> Programas </h5>
    </div>

    <mat-card>
        <div class="table-responsive-x">
            <table class="w-100 table-scroll-wrap animated fadeIn" mat-table [dataSource]="programsDataSource" matSort>
                <ng-container matColumnDef="temporal_id">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Id Temporal
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.temporal_id }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="temporal_school_id">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Temporal Shop Id
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.temporal_school_id }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="nombre">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Nombre
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.nombre }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="alias">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Alias
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.alias }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="descripcion">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Descripcion
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.descripcion }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="nivel">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Nivel
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.nivel }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="flow_request">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Flujo
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.flow_request }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="tipo_venta">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tipo de venta
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.tipo_venta }}</div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedProgramsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedProgramsColumns"></tr>
            </table>
        </div>
    </mat-card>
    <br>
    <mat-divider></mat-divider>
    <br>
    <div align='right'>
        <h5> Articulos </h5>
    </div>
    <mat-card>
        <div class="table-responsive-x">
            <table class="w-100 table-scroll-wrap animated fadeIn" mat-table [dataSource]="articlesDataSource" matSort>
                <ng-container matColumnDef="temporal_program_id">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Temporal Program Id
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.temporal_program_id }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="nombre">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Nombre
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.nombre }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="isbn">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Isbn
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.isbn }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="serie">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Serie
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.serie }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="nivel">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Nivel
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.nivel }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="subnivel">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Subnivel
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.subnivel }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="formato">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Formato
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.formato }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="version">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Version
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.version }}</div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedArticlesColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedArticlesColumns"></tr>
            </table>
        </div>
    </mat-card>
    <br>
    <div *ngIf="!bulkData?.approved" align='center'>
        <button mat-raised-button style="color: cornsilk; background-color: #388e3c; margin-right: 5px;"
            (click)="approveOrder()">
            Aprobar Petición
        </button>

        <button mat-raised-button style="color: cornsilk; background-color: #ff4081; margin-left: 5px;"
            (click)="disapproveOrder()">
            Rechazar Petición
        </button>
    </div>
    <br>
</div>