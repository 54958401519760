export class User{
  constructor(
    public _id: string,
    public id_parent: string,
    public id_shop: string,
    public id_category: string,
    public id_faculty : string,
    public id_campus  : string,
    public name: string,
    public father_last_name: String,
    public mother_last_name: String,
    public email: string,
    public password: string,
    public rol: string,
    public enrollment: string,
    public phone: string,
    public city: string,
    public state: string,
    public postal_code: string,
    public terms_conditions: string,
    public parent_name: string,
    public active: string,
    public validation: string,
    public active_session: string
  ){}
}
