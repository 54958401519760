import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ArticleService } from 'src/app/Services/article.service';
import { OrderService } from 'src/app/Services/order.service';
import { ParentService } from 'src/app/Services/parent.service';
import { SnackbarService } from 'src/app/Services/snackbar.service';

export interface Parents {
  nombre: string,
}

//Interfaces
export interface Articles {
  isbn: string,
  nombre: string,
  level: string,
  format: string,
  image: string,
  qty: string,
}

@Component({
  selector: 'app-order-request-pl',
  templateUrl: './order-request-pl.component.html',
  styleUrls: ['./order-request-pl.component.css'],
  providers: [ParentService, ArticleService, OrderService]
})
export class OrderRequestPlComponent implements OnInit {
  isLinear = false;
  emailPattern: any = /\S+@\S+\.\S+/;

  order_form: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  //Filter of parents
  public parentCtrl = new FormControl()
  public parents
  public filters_parents_array: Observable<Parents[]>

  //FiltersArray
  articleCtrl = new FormControl()
  articles_array: Observable<Articles[]>
  articles: Articles[]

  public order = {
    requester_name: null,
    requester_email: null,
    requester_phone: null,
    parent_name: null,
    parent_id: null,
    article_name: null,
    virt_article_name: null,
    article_id: null,
    image: null,
    virt_image: null,
    isbn: null,
    virt_isbn: null,
    serie: null,
    formato: null,
    nivel: null,
    version: null,
    qty: null
  }

  constructor(
    private formGroup: FormBuilder,
    private _snackBar: SnackbarService,
    private _parentService: ParentService,
    private _articleService: ArticleService,
    private _orderService: OrderService) { }

  ngOnInit() {

    this.firstFormGroup = this.formGroup.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.formGroup.group({
      secondCtrl: ['', Validators.required]
    });

    this.order_form = this.formGroup.group({
      name: ["", [Validators.required, Validators.maxLength(64)]],
      email: [
        "@" + 'example' + ".com",
        [
          Validators.required,
          Validators.pattern(this.emailPattern),
          Validators.maxLength(64),
        ],
      ],
      fullphone: ["", [Validators.required, Validators.maxLength(64)]],
      code_number: [0, [Validators.required, Validators.maxLength(64)]],
    })
  }

  getParentOption(name, id) {

    this.order.parent_id = id
    this.order.parent_name = name
  }

  getProductData(opt) {
    this.order.article_id = opt.idarticulo
    this.order.article_name = opt.nombre
    this.order.virt_article_name = opt.nombre_virtual
    this.order.isbn = opt.isbn
    this.order.virt_isbn = opt.isbn_virtual
    this.order.image = opt.imagen
    this.order.virt_image = opt.imagen_virtual
    this.order.serie = opt.serie
    this.order.formato = opt.formato
    this.order.nivel = opt.nivel
    this.order.version = opt.version
    //console.log(this.order);

  }

  validateFirstForm() {
    //console.log(this.order_form.value);
    Object.entries(this.order_form.value).forEach(([key, value]) => {
      let counter = 0

      if (value == '') {
        // console.log(key, value);
        counter++
        this._snackBar.openSnackBar(`El campo ${key} esta vacio, por favor completa todos los datos`, 'ok')
      }
      if (counter > 0) { return }
    })

    this.order.requester_name = this.order_form.value.name
    this.order.requester_email = this.order_form.value.email
    this.order.requester_phone = this.order_form.value.fullphone

    this._parentService.getParentS().subscribe(response => {
      if (response.status == 'success') {
        //console.log(response);
        this.parents = response.parents

        this.filters_parents_array = this.parentCtrl.valueChanges.pipe(
          startWith(''),
          map(parent => (parent ? this._filterParent(parent) : this.parents.slice()))
        )
      }
    }, error => {
      if (error.error) {
        this._snackBar.openSnackBar('La conexión falló: ', error.error.message)
      }
    })
  }

  validateSecondForm() {
    this._articleService.getAllArticles().subscribe(response => {
      if (response.status == 'success') {
        this.articles = response.articles
        //Activate async filter
        this.articles_array = this.articleCtrl.valueChanges.pipe(
          startWith(''),
          map(article => (article ? this._filterArticle(article) : this.articles.slice()))
        )

      }
    })
  }

  createOrder() {
    this.order.qty = this.order_form.value.code_number
    // Object.entries(this.order).forEach(([key, value]) => {
    //   let counter = 0

    //   if (value == '') { counter++ }
    //   if (counter > 0) {
    //     return this._snackBar.openSnackBar(`Verifica haber llenado todos los campos correctamente.`, 'ok')
    //   }
    // })

    this._orderService.createOrderInDrive(this.order).subscribe(r => {
      if (r.status == 'success') {

        this._snackBar.openSnackBar('Nueva orden creada, tu pedido se encuentra en revisión.', 'ok')
        this.order_form.reset()

        window.location.reload()
      }
    })
    //call endpoint reload table and create a request view
  }

  _filterParent(value: string): Parents[] {

    if (value) {
      return this.parents.filter(a => a.nombre.toLowerCase().indexOf(value) === 0)

    }

  }

  _filterArticle(value: string): Articles[] {
    if (value) {

      let searchByName = this.articles.filter(a => {
        //scape crap data
        if (a.nombre) {
          return a.nombre.toLowerCase().indexOf(value) === 0
        }
      })

      let searchByIsbn = this.articles.filter(a => {
        //scape crap data
        if (a.isbn) {
          return a.isbn.toLowerCase().indexOf(value) === 0
        }
      })

      if (searchByName.length >= 1) {
        return this.articles.filter(a => {
          //scape crap data
          if (a.nombre) {
            return a.nombre.toLowerCase().indexOf(value) === 0
          }
        })
      }

      if (searchByIsbn.length >= 1) {
        return this.articles.filter(a => {
          //scape crap data
          if (a.isbn) {
            return a.isbn.toLowerCase().indexOf(value) === 0
          }
        })
      }

    }

  }

}
