import { Component, OnInit, Inject } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
//Get data from the other component
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'

//Services
import { ValidationService } from '../../../Services/validation.service'
//Dialogs
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'validation-modal',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.css'],
  providers: [ValidationService],
})
export class validationComponent implements OnInit {
  access: FormGroup
  server_code
  request: boolean = false
  clicked = false
  clicked2 = false
  constructor(
    private dialogRef: MatDialogRef<validationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formGroup: FormBuilder,
    private _validationService: ValidationService,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.access = this.formGroup.group({
      code: ['', [Validators.required]],
    })

    console.log(this.data)
  }

  requestCodeBySMS() {
    this.request = false
    let params = this.data
    //Request code
    this._validationService.getValidationCodeBySms(params).subscribe(response => {
      //console.log(response);
      if (response.status == 'success') {
        this.server_code = response.parsed_code

        let message = 'Tu código ha sido enviado revisa tu teléfono!'
        let action = 'Ok'
        this.openSnackBar(message, action)
      } else {
        console.log('server error')
      }
    })
  }

  requestCodeByEmail() {
    let params = this.data
    //Request code
    this._validationService.getValidationCodeByEmail(params).subscribe(response => {
      //console.log(response);
      if (response.status == 'success') {
        this.server_code = response.parsed_code
        //console.log(response);

        let message = 'Tu código ha sido enviado a tu email, revisa tu bandeja!'
        let action = 'Ok'
        this.openSnackBar(message, action)
      } else {
        console.log('server error')
      }
    })
  }

  validateCode() {
    let typed_code = this.access.value.code
    let server_code = this.server_code
    let parent = JSON.parse(localStorage.getItem('parent'))

    if (typed_code == server_code) {
      //Create local storage var
      this.dialogRef.close()
      localStorage.setItem('verification', JSON.stringify(true))
      console.log(`El codigo de sms: ${typed_code} es igual al codigo del server: ${server_code}`);

      this._router.navigate([parent + '/admin-panel/reports'])
    } else {
      //Corrective logic
      localStorage.setItem('verification', JSON.stringify(false))
      //Clean storage and reload
      localStorage.removeItem('identity')
      localStorage.removeItem('token')
      localStorage.removeItem('current_user')
      //localStorage.removeItem('cart');
      localStorage.removeItem('cart_info')
      localStorage.removeItem('coordinator_selections')
      this.dialogRef.close()

      this._router.navigate([parent + '/session/signin'])

      //Acumular errores
    }

    //console.log(typed_code);
    //console.log(server_code);
  }

  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
      duration: 5000,
    })
  }
}
