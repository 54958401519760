export class Cart{
  constructor(
    public _id: string,
    public id_purchase: string,
    public id_pay: string,
    public id_user: string,
    public type: string,
    public max_quantity: string,
    public total_articles: string,
    public subtotal_price: string,
    public discount_price: string,
    public total_shipping: string,
    public total_price: string,
    public status: string,
    public legend_status: string,
  ){}
}
