<div class="inner-container bg-white section-gap-lg not-found-page">
  <div class="container">
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <div
        fxFlex.xs="100"
        fxFlex.sm="100"
        fxFlex.md="80"
        fxFlex.lg="40"
        fxFlex.xl="40"
      >
        <mat-card class="text-center pb-5">
          <h1>Ups!</h1>
          <img
            src="https://res.cloudinary.com/ivision/image/upload/v1594832539/logos/close_shop_closed_sign-512_aiignz.png"
            class="animated lightSpeedIn delay-4s"
            style="width: 40%;"
          />
          <h5 class="mb-3">
            La venta en la tienda no esta habilitada por el momento.
          </h5>
          <button
            mat-raised-button
            class="button-lg w-100"
            color="accent"
            routerLink="/{{ parent }}/user_dashboard/order-history"
          >
            Ir a mis compras
          </button>
        </mat-card>
      </div>
    </div>
  </div>
</div>
