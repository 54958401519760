<h1 mat-dialog-title>
  <span class="material-icons" style="vertical-align: middle;">
    perm_phone_msg
  </span>
  Verificación
</h1>
<div mat-dialog-content align="center">
  <p><mat-label> Escoje un método para verificar tu acceso </mat-label></p>

  <mat-tab-group class="mb-3">
    <mat-tab label="SMS">
      <form [formGroup]="access">
        <div class="mb-4">
          <mat-form-field class="example-full-width mt-4">
            <mat-label>Mi código</mat-label>
            <input
              matInput
              #code
              maxlength="6"
              formControlName="code"
              placeholder="Ex. 941057"
              value=""
              required
            />
            <mat-hint>{{ code.value.length }} / 6</mat-hint>
          </mat-form-field>
        </div>
      </form>
      <button
        mat-raised-button
        color="accent"
        [disabled]="clicked"
        (click)="requestCodeBySMS(); clicked = true"
        style="margin-right: 20px;"
      >
        Solicitar
      </button>
      <button mat-raised-button color="accent" (click)="validateCode()">
        Validar
      </button>
    </mat-tab>
    <mat-tab label="Email">
      <form [formGroup]="access">
        <div class="mb-4">
          <mat-form-field class="example-full-width mt-4">
            <mat-label>Mi código</mat-label>
            <input
              matInput
              #code
              maxlength="6"
              formControlName="code"
              placeholder="Ex. 941057"
              value=""
              required
            />
            <mat-hint>{{ code.value.length }} / 6</mat-hint>
          </mat-form-field>
        </div>
      </form>
      <button
        mat-raised-button
        color="accent"
        [disabled]="clicked2"
        (click)="requestCodeByEmail(); clicked2 = true"
        style="margin-right: 20px;"
      >
        Solicitar
      </button>
      <button mat-raised-button color="accent" (click)="validateCode()">
        Validar
      </button>
    </mat-tab>
  </mat-tab-group>
</div>
