<!--  Top menu -->
<nav class="app-nav" *ngIf="cordi_items">
  <ul class="app-nav-list custom-menu-list">
    <li class="app-nav-item" *ngFor="let item of cordi_items" [ngClass]="{ mega: item.mega }">
      <ng-container *ngIf="item.type == 'sub'">
        <a class="nav-link" routerLinkActive="active-link">
          <i class="material-icons menu-icons">{{ item.icon }}</i
          >{{ item.name | translate }}
        </a>
      </ng-container>
      <a class="nav-link" *ngIf="item.type == 'link'" [routerLink]="['/' + item.state]" routerLinkActive="active-link">
        <i class="material-icons menu-icons">{{ item.icon }}</i
        >{{ item.name | translate }}
      </a>
      <ul class="sub-menu" *ngIf="item.children && item.children.length > 0">
        <li *ngFor="let child of item.children">
          <a
            class="nav-link"
            [ngClass]="{ 'menu-item-has-children': child.type == 'subChild' }"
            [routerLink]="['/' + child.state]"
            routerLinkActive="active-link"
          >
            <i class="material-icons menu-icons">{{ child.icon }}</i
            >{{ child.name | translate }}
          </a>
          <ul class="sub-menu" *ngIf="child.children && child.children.length > 0">
            <li *ngFor="let subChild of child.children">
              <a *ngIf="subChild.type == 'queryParams'" class="nav-link" (click)="redirectTo(subChild)">
                {{ subChild.name | translate }}
              </a>
              <a *ngIf="subChild.type == 'link'" class="nav-link" [routerLink]="['/' + subChild.state]">
                {{ subChild.name | translate }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</nav>

<nav class="app-nav" *ngIf="user_items">
  <ul class="app-nav-list">
    <li class="app-nav-item" *ngFor="let item of user_items" [ngClass]="{ mega: item.mega }">
      <ng-container *ngIf="item.type == 'sub'">
        <a class="nav-link" routerLinkActive="active-link">
          <i class="material-icons menu-icons">{{ item.icon }}</i
          >{{ item.name | translate }}
        </a>
      </ng-container>
      <a class="nav-link" *ngIf="item.type == 'link'" [routerLink]="['/' + item.state]" routerLinkActive="active-link">
        <i class="material-icons menu-icons">{{ item.icon }}</i
        >{{ item.name | translate }}
      </a>
      <ul class="sub-menu" *ngIf="item.children && item.children.length > 0">
        <li *ngFor="let child of item.children">
          <a
            class="nav-link"
            [ngClass]="{ 'menu-item-has-children': child.type == 'subChild' }"
            [routerLink]="['/' + child.state]"
            routerLinkActive="active-link"
          >
            <i class="material-icons menu-icons">{{ child.icon }}</i
            >{{ child.name | translate }}
          </a>
          <ul class="sub-menu" *ngIf="child.children && child.children.length > 0">
            <li *ngFor="let subChild of child.children">
              <a *ngIf="subChild.type == 'queryParams'" class="nav-link" (click)="redirectTo(subChild)">
                {{ subChild.name | translate }}
              </a>
              <a *ngIf="subChild.type == 'link'" class="nav-link" [routerLink]="['/' + subChild.state]">
                {{ subChild.name | translate }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</nav>
