//Url for the data requests
export var global = {
  url: "https://links2shop.com/api/ws/",
  mode: "production_check_on",
};

//Production Enviroment
//https://links2shop.com/api/ws/

//Local Enviroment
//http://localhost:2997/api/ws/
