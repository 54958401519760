import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItems } from '../../../Core/menu/menu-items/menu-items';
import { TemplateService } from "../../../Services/template.service";
@Component({
  selector: 'FooterOne',
  templateUrl: './FooterOne.component.html',
  styleUrls: ['./FooterOne.component.scss'],
  providers: [TemplateService]
})
export class FooterOneComponent implements OnInit {
  footer = JSON.parse(localStorage.getItem('footer')) ? JSON.parse(localStorage.getItem('footer')) : undefined
  constructor(public menuItems: MenuItems,
    public translate: TranslateService,
    private _templateService: TemplateService) { }

  ngOnInit() {

    if (!this.footer) {
      this._templateService.getLogos().subscribe(res => {
        //console.log(res);
        this.footer = res.result.logo_footer

        //console.log(this.footer);
        localStorage.setItem('footer', JSON.stringify(this.footer))
      })
    }

  }

}
