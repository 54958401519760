import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'embryo-CtaTwo',
  templateUrl: './CTA-Two.component.html',
  styleUrls: ['./CTA-Two.component.scss']
})
export class CTATwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
