<div class="home-main-banner mb-5">
  <div class="container">
    <div fxLayout='row wrap' fxLayoutGap="0px">
      <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="63" fxFlex.xl="63">
        <div class="home-slider">
          <embryo-HomePageOneSlider [isRTL]="embryoService.isDirectionRtl"></embryo-HomePageOneSlider>
        </div>
      </div>
      <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="37" fxFlex.xl="37">
        <div class="aside-banner">
          <a [routerLink]="['/products/accessories']" class="card-shadow"> <img src="assets/images/aside-1.jpg" alt="side" width="547" height="315"></a>
        </div>

        <div class="aside-banner">
          <a [routerLink]="['/products/accessories']" class="card-shadow"><img src="assets/images/aside-2.jpg" alt="side" width="547" height="315"></a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="feature-section">
  <div class="container">
    <embryo-Features></embryo-Features>
  </div>
</div>
<div class="shop-card-wrapper section-gap" *ngIf="productsArray">
  <div class="block-title text-center">
    <h2>Featured Products</h2>
  </div>
  <div class="container">
    <mat-tab-group [(selectedIndex)]="embryoService.featuredProductsSelectedTab">
      <mat-tab label="Men"></mat-tab>
      <mat-tab label="Women"></mat-tab>
      <mat-tab label="Gadgets"></mat-tab>
      <mat-tab label="Accessories"></mat-tab>
    </mat-tab-group>
   <ng-container *ngIf="onFeaturedSelectedTab(embryoService.featuredProductsSelectedTab)">
      <ngx-slick-carousel *ngIf="embryoService.isDirectionRtl" class="carousel" [config]="rtlSlideConfig">
        <div ngxSlickItem *ngFor="let product of productsSliderData; let i=index">
          <embryo-ProductCard [product]="product" (addToCart)="addToCart($event)" [currency]="embryoService.currency" (addToWishlist)="addToWishlist($event)" [index]="i" type="featured"></embryo-ProductCard>
        </div>
      </ngx-slick-carousel>
      <ngx-slick-carousel *ngIf="!embryoService.isDirectionRtl" class="carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let product of productsSliderData; let i=index">
          <embryo-ProductCard [product]="product" (addToCart)="addToCart($event)" [currency]="embryoService.currency" (addToWishlist)="addToWishlist($event)" [index]="i" type="featured"></embryo-ProductCard>
        </div>
      </ngx-slick-carousel>
   </ng-container>
  </div>
</div>
<div class="detail-offer section-gap bg-white">
  <div class="container" *ngIf="productsArray">
    <embryo-DealOfTheDay [currency]="embryoService?.currency" [singleProduct]="productsArray?.men['2']"></embryo-DealOfTheDay>
  </div>
</div>
<div class="quick-shop">
  <embryo-Sales></embryo-Sales>
</div>
<div class="shop-card-wrapper section-gap" *ngIf="productsArray">
  <div class="block-title text-center">
    <h2>New Arrivals</h2>
  </div>
  <div class="container">
    <mat-tab-group [(selectedIndex)]="embryoService.newArrivalSelectedTab">
      <mat-tab label="Men"></mat-tab>
      <mat-tab label="Women"></mat-tab>
      <mat-tab label="Gadgets"></mat-tab>
      <mat-tab label="Accessories"></mat-tab>
    </mat-tab-group>
    <ng-container *ngIf="onNewArrivalsSelectedTab(embryoService.newArrivalSelectedTab)">
      <ngx-slick-carousel *ngIf="embryoService.isDirectionRtl" class="carousel" [config]="rtlSlideConfig">
        <div ngxSlickItem *ngFor="let product of newProductsSliderData; let i=index">
          <embryo-ProductCard [product]="product" (addToCart)="addToCart($event)" [currency]="embryoService.currency" (addToWishlist)="addToWishlist($event)" [index]="i" type="new"></embryo-ProductCard>
        </div>
      </ngx-slick-carousel>
      <ngx-slick-carousel *ngIf="!embryoService.isDirectionRtl" class="carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let product of newProductsSliderData; let i=index">
          <embryo-ProductCard [product]="product" (addToCart)="addToCart($event)" [currency]="embryoService.currency" (addToWishlist)="addToWishlist($event)" [index]="i" type="new"></embryo-ProductCard>
        </div>
      </ngx-slick-carousel>
   </ng-container>
  </div>
</div>
<div class="testimonial-wrapper" *ngIf="productReviews && productReviews.length>0">
  <div class="block-title text-center">
    <h2>Customer Reviews</h2>
  </div>
  <div class="container">
    <embryo-Review [reviews]="productReviews"></embryo-Review>
  </div>
</div>
<div class="post-wrapper section-gap">
  <div class="block-title text-center">
    <h2>Latest From Blogs</h2>
  </div>
  <div class="container" *ngIf="blogList">
    <embryo-Grid3 [blogList]="blogList"></embryo-Grid3>
  </div>
</div>
<div class="subscribe-wrapper bg-brown section-gap">
  <div class="container">
    <embryo-SubscribeOne></embryo-SubscribeOne>
  </div>
</div>

<div class="shop-card-wrapper section-gap ">
  <div class="block-title text-center">
    <h2>Shop By Brands</h2>
  </div>
  <div class="container">
    <embryo-BrandsLogo [isRTL]="embryoService.isDirectionRtl"></embryo-BrandsLogo>
  </div>
</div>
