import { Injectable } from "@angular/core";

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

let parent = JSON.parse(localStorage.getItem("parent"));

let flow = JSON.parse(localStorage.getItem("flow"));

let MENUITEMS = [
  {
    state: parent + "/admin-panel/reports",
    name: "Mi dashboard",
    type: "link",
    icon: "home",
  },
  {
    state: parent + "/admin-panel/deliveries",
    name: "Mis entregas",
    type: "link",
    icon: "local_shipping",
  },
  {
    state: parent + "/admin-panel/helpers",
    name: "Mis ayudantes",
    type: "link",
    icon: "supervised_user_circle",
  },
];

if (flow) {
  //On Scenario 3 we add a new View: Comission
  if (flow.flow_request == "Codes_Agent_Fee") {
    MENUITEMS.push({
      state: parent + "/admin-panel/comission",
      name: "Mi comisión",
      type: "link",
      icon: "monetization_on",
    });
  }
}

@Injectable()
export class AdminMenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
