import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { log } from 'util'
import { JsonpInterceptor } from '@angular/common/http'

@Component({
  selector: 'embryo-HeaderUserProfileDropdown',
  templateUrl: './HeaderUserProfileDropdown.component.html',
  styleUrls: ['./HeaderUserProfileDropdown.component.scss'],
})
export class HeaderUserProfileDropdownComponent implements OnInit {
  public user = false
  public parent

  public smart_selections = JSON.parse(localStorage.getItem('smart_selections'))

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    let item = JSON.parse(localStorage.getItem('current_user'))

    if (item) {
      this.user = true
    } else {
      this.user = false
    }
  }

  logout() {
    localStorage.removeItem('identity')
    localStorage.removeItem('token')
    localStorage.removeItem('current_user')
    localStorage.removeItem('cart')
    localStorage.removeItem('cart_info')
    localStorage.removeItem('flow')

    this.parent = JSON.parse(localStorage.getItem('parent'))

    this.router.navigate([this.parent + '/session/signin'])
  }

  navToProfile() {
    this.parent = JSON.parse(localStorage.getItem('parent'))

    this.router.navigate([this.parent + '/user_dashboard/profile'])
  }
}
