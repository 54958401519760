<form [formGroup]="_login">
  <div class="mb-4">
    <mat-form-field class="w-100">
      <input matInput type="email" placeholder="Email" formControlName="email" required />
      <mat-error>Completa los campos de manera correcta.</mat-error>
    </mat-form-field>
  </div>
  <div class="mb-4">
    <div fxLayout="row wrap">
      <div fxFlex.xs="55" fxFlex.sm="55" fxFlex.md="55" fxFlex.lg="55" fxFlex.xl="55">
        <mat-form-field class="w-100">
          <input matInput type="password" placeholder="Password" formControlName="password" required />
          <mat-error>Completa los campos de manera correcta.</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex.xs="45" fxFlex.sm="45" fxFlex.md="45" fxFlex.lg="45" fxFlex.xl="45"
        style="padding-top: 28px; padding-left: 15px">
        <a routerLink="/{{ parent }}/session/forgot-password">¿Olvidaste tu contraseña?</a>
      </div>
    </div>
  </div>
  <div class="mb-3">
    <mat-checkbox>Recuérdame</mat-checkbox>
  </div>
  <div class="text-center">
    <button class="custom-login-btn button-lg mb-3" type="submit" mat-raised-button (click)="login()">Login</button>
    <button mat-stroked-button class="custom-register-btn button-lg mb-3 ml-4" type="submit"
      (click)="navigateToSignup()">
      Registrarme
    </button>
  </div>
  <!-- <p>No tienes una cuenta? <a (click)="navigateToSignup()" class="pointer"> Haz click en registrarme</a></p> -->
</form>
<div align="center">
  <br />
  <a class="btn-anim" (click)="openTermsAndConditions()"> <i class="fas fa-file-alt"></i> Términos y condiciones </a>
</div>