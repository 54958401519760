import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from "../../../Services/user.service";

@Component({
	selector: 'embryo-header-user-profile',
	templateUrl: './HeaderUserProfileDropdown.component.html',
	styleUrls: ['./HeaderUserProfileDropdown.component.scss'],
	providers: [UserService]
})

export class HeaderUserProfileDropdownComponent implements OnInit {
	parent;
	identity;
	currentUser;
	constructor(public router: Router,
		private _userService: UserService) { }
	activeHelper = false;
	ngOnInit() {

		//Talk about remove the verification if persists or not
		localStorage.removeItem('verification');

		let user = JSON.parse(localStorage.getItem('identity'));

		if (user.rol == 'asistente_entregas') {
			console.log('asistente');
			this.activeHelper = true;
		}
		//Get updated user in every load
		this.identity = JSON.parse(localStorage.getItem('identity'));
		let id = this.identity.idusuario;

		this._userService.getUser(id).subscribe(response => {
			if (response) {
				//console.log(response);

				this.currentUser = response.userFound;
				localStorage.setItem('current_user', JSON.stringify(this.currentUser));

				//Get the program of the user
				let user_program = JSON.parse(localStorage.getItem('current_user'));

			}
		});




	}

	//log out method 
	logout() {
		localStorage.removeItem('identity');
		localStorage.removeItem('token');
		localStorage.removeItem('current_user');
		//localStorage.removeItem('cart');
		localStorage.removeItem('cart_info');
		localStorage.removeItem('coordinator_selections');
		localStorage.removeItem('flow');

		this.parent = JSON.parse(localStorage.getItem('parent'));

		this.router.navigate([this.parent + '/session/signin']);
	}

	navToProfile() {
		this.parent = JSON.parse(localStorage.getItem('parent'));

		this.router.navigate([this.parent + '/user_dashboard/profile']);
	}
}
