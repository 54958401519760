import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes } from "@angular/router";
import { MainAdminPanelComponent } from "./Main/Main.component";

//Guard
import { HelperGuard } from "../Services/helper.guard";
import { CoordinatorGuard } from "../Services/coordi.guard";

export const AdminPanelRoutes: Routes = [
  {
    path: ":parent/admin-panel",
    redirectTo: ":parent/admin-panel/reports",
    pathMatch: "full",
  },
  {
    path: ":parent/admin-panel",
    component: MainAdminPanelComponent,
    children: [
      {
        path: "reports",
        loadChildren: () =>
          import("./Reports/Reports.module").then((m) => m.ReportsModule),
        canActivate: [CoordinatorGuard],
      },
      {
        path: "invoices",
        loadChildren: () =>
          import("./Invoices/Invoices.module").then((m) => m.InvoicesModule),
        canActivate: [CoordinatorGuard],
      },
      {
        path: "products",
        loadChildren: () =>
          import("./Products/Products.module").then((m) => m.ProductsModule),
        canActivate: [CoordinatorGuard],
      },
      {
        path: "account",
        loadChildren: () =>
          import("./AdminAccount/AdminAccount.module").then(
            (m) => m.AdminAccountModule
          ),
        canActivate: [CoordinatorGuard],
      },
      {
        path: "deliveries",
        loadChildren: () =>
          import("./Deliveries/deliveries.module").then(
            (m) => m.DeliveriesModule
          ),
        canActivate: [HelperGuard],
      },
      {
        path: "helpers",
        loadChildren: () =>
          import("./Helpers/helpers.module").then((m) => m.HelpersModule),
        canActivate: [CoordinatorGuard],
      },
      {
        path: "general-dashboard",
        loadChildren: () =>
          import("./Admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: "agent-view",
        loadChildren: () =>
          import("./Agent/agent.module").then((m) => m.AgentModule),
        canActivate: [CoordinatorGuard],
      },
      {
        path: "master",
        loadChildren: () =>
          import("./Master/master.module").then((m) => m.MasterModule)
      },
      {
        path: "comission",
        loadChildren: () =>
          import("./Comission/comission.module").then((m) => m.ComissionModule),
        canActivate: [CoordinatorGuard],
      },
      {
        path: "uploads",
        loadChildren: () =>
          import("./Uploads/uploads.module").then((m) => m.UploadsModule),
        canActivate: [CoordinatorGuard],
      },
    ],
  },
];
