import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cart } from '../Models/cart.model';

import { global } from "../Config/config";
import { log } from 'util';

@Injectable()
export class CartService {
  public url: string;
  public identity;
  public token;


  constructor(private _http: HttpClient) {
    this.url = global.url;
   }

   prueba(){
    return 'Hola mundo desde el servicio de angular';
  }

  loadMyCart(userid): Observable<any>{

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.get(this.url+'/load-cart/' + userid, {headers: headers});
  }

  addMyProduct(elements): Observable<any>{

    //Get the params
    let params = elements;

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.post(this.url+'add-item-cart', params, {headers: headers});
  }

  updateMyProduct(elements): Observable<any>{

    //Get the params
    let params = elements;

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.put(this.url+'update-item-cart', params, {headers: headers});
  }

  deleteCartDetail(id): Observable<any>{

    //Get the params
    let cartDetailId = id;

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.delete(this.url+'delete-cart-details/'+ cartDetailId,  {headers: headers});
  }

  getMyCartDetails(userid, idcarrito): Observable<any>{

    //Get the params
    let userId = userid;
    let cartId = idcarrito;

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.get(this.url+'get-cart-details/'+ userId + '/' + cartId,  {headers: headers});
  }

  updateCartSale(data): Observable<any>{

    //Get the params
    let params = data;
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.put(this.url+'update-cart-sale', params, {headers: headers});
  }

  updateCartPayment(data): Observable<any>{

    //Get the params
    let params = data;
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.put(this.url+'update-cart-payment', params, {headers: headers});
  }

}
