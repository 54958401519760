import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

import { ShopService } from "../../Services/shop.service";

@Component({
    selector: 'app-shop-request',
    templateUrl: './shopRequestModule.component.html',
    styleUrls: ['./shopRequestModule.component.css'],
    providers: [ShopService]
})
export class ShopRequestModuleComponent implements OnInit {
    //Data request containers
    shopsData = []
    programsData = []
    articlesData = []
    //Tables declarations and asignments
    shopsDataSource = new MatTableDataSource<any>(this.shopsData)
    programsDataSource = new MatTableDataSource<any>(this.programsData)
    articlesDataSource = new MatTableDataSource<any>(this.articlesData)

    //Columns for tables declaration
    displayedShopsColumns: string[] = [
        'temporal_id',
        'nombre',
        'alias',
        'parent',
        'calle',
        'campus',
        'pais',
        'ciudad',
        'estado',
        'colonia',
        'edificio',
        'codigo_postal',
        'num_ext',
        'num_int',
        'telefono'
    ]
    displayedProgramsColumns: string[] = [
        'temporal_id',
        'temporal_school_id',
        'nombre',
        'alias',
        'descripcion',
        'nivel',
        'flow_request',
        'tipo_venta'
    ]
    displayedArticlesColumns: string[] = [
        'temporal_program_id',
        'nombre',
        'isbn',
        'serie',
        'nivel',
        'subnivel',
        'formato',
        'version'
    ]

    //Paginators viewchilds
    @ViewChild('shopsTablePaginator')
    shopsTablePaginator: MatPaginator
    @ViewChild('programsTablePaginator')
    programsTablePaginator: MatPaginator
    @ViewChild('articlesTablePaginator')
    articlesTablePaginator: MatPaginator

    //MatSor viewchilds
    @ViewChild(MatSort, { static: true }) sort: MatSort
    public bulkData = null
    constructor(
        private actRoute: ActivatedRoute,
        private _shopService: ShopService
    ) { }


    ngOnInit() {
        this.actRoute.paramMap.subscribe(params => {
            let id = params.get('id');

            this._shopService.getShopRequest(id).subscribe(response => {
                if (response.status == 'success') {
                    let parsed_obj = JSON.parse(response.shop_request)
                    //console.log(parsed_obj);
                    this.bulkData = parsed_obj
                    this.bulkData.id = id
                    this.shopsData = parsed_obj.shops
                    this.shopsDataSource = new MatTableDataSource<any>(this.shopsData)
                    this.shopsDataSource.paginator = this.shopsTablePaginator
                    this.shopsDataSource.sort = this.sort

                    this.programsData = parsed_obj.programs
                    this.programsDataSource = new MatTableDataSource<any>(this.programsData)
                    this.programsDataSource.paginator = this.programsTablePaginator
                    this.programsDataSource.sort = this.sort

                    this.articlesData = parsed_obj.articles
                    this.articlesDataSource = new MatTableDataSource<any>(this.articlesData)
                    this.articlesDataSource.paginator = this.articlesTablePaginator
                    this.articlesDataSource.sort = this.sort
                }
            })
        });
    }

    approveOrder() {
        Swal.fire({
            title: 'Esta seguro?',
            text: "Al aprobar la peticion se crearan las nuevas tiendas inmediatamente",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, aprobar!'
        }).then((result) => {
            if (result.value) {
                this._shopService.createShopsInBulk(this.bulkData).subscribe(res => {
                    if (res.status == 'success') {
                        //console.log(res);
                        location.reload()
                    }
                })


            }
        })

    }

    disapproveOrder() {

    }
}
