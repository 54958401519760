import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../Models/user.model';

import { global } from "../Config/config";

@Injectable()
export class UserService {
  public url: string;
  public googleIdentity;
  public token;


  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  prueba() {
    return 'Hola mundo desde el servicio de angular';
  }

  register(user): Observable<any> {
    //Convertir objeto a json string
    let params = JSON.stringify(user);

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.post(this.url + 'create-user', params, { headers: headers });

  }

  login(user, gettoken = null): Observable<any> {

    //Comprobar si llega el gettoken
    if (gettoken != null) {
      user.gettoken = gettoken;
    }

    let params = JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.post(this.url + 'login', params, { headers: headers })

  }

  forgetMyPassword(user): Observable<any> {


    let params = JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.post(this.url + 'forget-my-password', params, { headers: headers })

  }

  getUser(id): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.get(this.url + 'get-user/' + id, { headers: headers })
  }

  userUpdate(user, id): Observable<any> {
    let params = JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.put(this.url + 'update-user/' + id, params, { headers: headers })
  }

  userUpdateSchool(user): Observable<any> {
    let params = JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.put(this.url + 'update-user-school/', params, { headers: headers })
  }

  userUpdateProgram(user): Observable<any> {
    let params = JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.put(this.url + 'update-user-program/', params, { headers: headers })
  }

  changeMyPassword(value, id): Observable<any> {
    let params = JSON.stringify(value);
    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.put(this.url + 'change-user-password/' + id, params, { headers: headers })
  }

  getIdentity(): Observable<any> {

    let user = JSON.parse(localStorage.getItem('token'));

    return user;
  }

  activateAccount(id): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.put(this.url + 'activate-my-account/' + id, { headers: headers });
  }

  deactivateAccount(id): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.put(this.url + 'deactivate-my-account/' + id, { headers: headers });
  }

  activateAccountsInBulk(): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.put(this.url + 'activate-users-in-bulk', { headers: headers });
  }

  //Assitants
  getHelpersByShop(id): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.get(this.url + 'get-helpers-by-shop/' + id, { headers: headers });
  }

  //Update helper state activated/deactivated
  updateHelperState(id): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.put(this.url + 'update-helper-state/' + id, { headers: headers });
  }
  //Delete user
  deleteUser(id): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.delete(this.url + 'delete-user/' + id, { headers: headers });
  }

  //Update Helper
  updateUserTypeHelper(values): Observable<any> {

    let params = values;
    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.put(this.url + 'update-helper/', params, { headers: headers });
  }

  //Update user CRUD colle
  updateUserCrudCollection(values): Observable<any> {

    let params = values;
    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.put(this.url + 'update-user-crud-colle/', params, { headers: headers });
  }

  //Admin view services

  getAllTheUsersInTheSystem(size, page): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.get(this.url + `get-users/${size}/${page}`, { headers: headers });
  }

  getUsersLike(params): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.post(this.url + 'get-users-like/', params, { headers: headers });
  }

  //router.post('', user_controller.createUsersInBulkCrudCollection)

  getUsersFromSpreadsheet(): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.get(this.url + 'get-users-from-spreadsheet/', { headers: headers });
  }

  createUsersInBulk(params): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.post(this.url + 'create-users-in-bulk/', params, { headers: headers })

  }

  //Get coordinators
  getCoordinatorsBySchool(id): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.get(this.url + 'get-coordinators-in-school/' + id, { headers: headers });
  }

}
