import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { EmbryoService } from '../../../Services/Embryo.service';
//Global data
import { global } from "../../../Config/config";
//Services
import { ParentService } from "../../../Services/parent.service";

@Component({
   selector: 'HeaderOne',
   templateUrl: './HeaderOne.component.html',
   styleUrls: ['./HeaderOne.component.css'],
   providers: [ParentService]
})
export class HeaderOneComponent implements OnInit {

   toggleActive: boolean = false;
   cartProducts: any;
   popupResponse: any;
   wishlistProducts: any;

   guard_token: boolean = false;
   url;
   image_parent;

   main_parent

   constructor(public embryoService: EmbryoService,
      public _parentService: ParentService) {
      setTimeout(() => {
         this.main_parent = JSON.parse(localStorage.getItem('parent'));
         //console.log(this.main_parent);

      }, 3000);

   }

   ngOnInit() {
      let token = JSON.parse(localStorage.getItem('token'));

      if (token) {
         this.guard_token = true;
      } else {
         this.guard_token = false;
      }

      let parent = JSON.parse(localStorage.getItem('parent'));
      //Get parent name
      this.url = global.url;
      this._parentService.getParentInfo(parent).subscribe(response => {
         if (response.status == 'success') {
            this.image_parent = response.parentFound.image;
         }
      })


   }

   public toggleSearch() {
      document.querySelector('app-main').classList.toggle('form-open');
   }

   public toggleSidebar() {
      this.embryoService.sidenavOpen = !this.embryoService.sidenavOpen;
   }

   // public openConfirmationPopup(value:any) {
   //   let message = 'This comes from the header cart';
   //   let id = value;
   //    this.embryoService.confirmationPopup(message, id).
   //       subscribe(res => {this.popupResponse = res},
   //                 err => console.log(err),
   //                 ()  => this.getPopupResponse(this.popupResponse, value, 'cart')
   //                );
   // }
   //
   // public getPopupResponse(response:any, value:any, type) {
   //    if(response) {
   //       if(type == 'cart'){
   //          this.embryoService.removeLocalCartProduct(value);
   //       } else {
   //          this.embryoService.removeLocalWishlistProduct(value);
   //       }
   //    }
   // }

   public addAllWishlistToCart(values: any) {
      this.embryoService.addAllWishListToCart(values);
   }

   // public openWishlistConfirmationPopup(value:any) {
   //    let message = "Are you sure you want to add all products?";
   //    this.embryoService.confirmationPopup(message).
   //       subscribe(res => {this.popupResponse = res},
   //                 err => console.log(err),
   //                 ()  => this.getPopupResponse(this.popupResponse, value, 'wishlist')
   //                );
   // }

   public selectedCurrency(value) {
      this.embryoService.currency = value;
   }

   public selectedLanguage(value) {
      this.embryoService.language = value;
   }

   public addToCart(value) {
      this.embryoService.addToCart(value, 'wishlist');
   }
}
