<div class="counter-wrapper">
  <div class="counter-item">
    <span class="counter-digit">{{hours}}</span>
    <span class="counter-text">Hours</span>
  </div>
  <div class="counter-item">
    <span class="counter-digit">{{minutes}}</span>
    <span class="counter-text">Minutes</span>
  </div>
  <div class="counter-item">
    <span class="counter-digit">{{seconds}}</span>
    <span class="counter-text">Seconds</span>
  </div>
</div>
