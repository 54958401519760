import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { global } from "../Config/config";


@Injectable()
export class ValidationService {
  public url: string;


  constructor(private _http: HttpClient) {
    this.url = global.url;
   }

  getValidationCodeBySms(data): Observable<any>{


    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.post(this.url+'user-validation-by-sms/', params, {headers: headers})

  }

  getValidationCodeByEmail(data): Observable<any>{


    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-type', 'application/json');

    return this._http.post(this.url+'user-validation-by-email/', params, {headers: headers})

  }

}
