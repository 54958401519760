import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { global } from "../Config/config";

@Injectable()
export class CouponsService {
  public url: string;
  public identity;
  public token;

  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  createCoupon(params): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    return this._http.post(this.url + "create-coupon/", params, {
      headers: headers,
    });
  }

  getAllTheCoupons(): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    return this._http.get(this.url + "get-coupons/", { headers: headers });
  }

  getCoupon(name): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    return this._http.get(this.url + "get-coupon/" + name, {
      headers: headers,
    });
  }

  removeCoupon(id): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    return this._http.delete(this.url + "delete-coupon/" + id, {
      headers: headers,
    });
  }

  updateCoupons(params): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    return this._http.put(this.url + "update-coupon/", params, {
      headers: headers,
    });
  }

  updateCouponStatus(params): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    return this._http.put(this.url + "update-coupon-status/", params, {
      headers: headers,
    });
  }
}
