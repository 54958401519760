<div class="section-gap primary-bg subscribe-section-v2 relative">
  <div class="center-icon ">
    <i class="material-icons">
      check_box
    </i>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxLayout.xs="100" fxLayout.sm="100" fxLayout.md="50" fxLayout.lg="50" fxLayout.xl="50">
      <div>
        <div>
          <h3 class="text-inverse">Subscribe our Newsletter</h3>
          <p class="text-inverse">Stay up to date with our latest new and products</p>
        </div>
        <div>
          <form class="example-form">
            <div fxLayoutAlign="start center">
              <mat-form-field class="w-100">
                <input class="text-inverse" matInput placeholder="Your Email Address">
              </mat-form-field>
              <button mat-icon-button class="text-inverse">
                <i class="material-icons">send</i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
