<div class="container">
    <div align='center'>
        <br>
        <h4> Petición de orden de artículos {{ bulkData?.created_at }}</h4>

    </div>
    <br>
    <mat-divider></mat-divider>
    <br>
    <div align='right'>
        <h5> Resumen orden <div *ngIf="bulkDataObject?.status == 0; else approved" style="color: cornflowerblue;">
                Pendiente
            </div>
        </h5>
    </div>
    <mat-card>

        <div fxLayout="row wrap" class="cart-item">
            <div fxFlex.xs="30" fxFlex.sm="30" fxFlex.md="30" fxFlex.lg="30" fxFlex.xl="30" align='center'>
                <div>
                    Imágen libro:
                    <div>
                        <img src={{bulkDataObject?.image}} style="width: 33%;">
                    </div>
                </div>
                <div>
                    Imágen código:
                    <div>
                        <img src={{bulkDataObject?.virt_image}} style="width: 33%;">
                    </div>
                </div>
            </div>
            <div fxFlex.xs="70" fxFlex.sm="70" fxFlex.md="70" fxFlex.lg="70" fxFlex.xl="70" align='left'
                style="padding-left: 150px;">
                <p>
                    Artículo: {{ bulkDataObject?.article_name }} <br>
                    Artículo Virtual: {{ bulkDataObject?.virt_article_name }}
                </p>
                <p>
                    Cantidad Solicitada: {{ bulkDataObject?.qty }}
                </p>
                <p>
                    {{ bulkDataObject?.nivel }} | {{ bulkDataObject?.formato }} | {{ bulkDataObject?.serie }} | {{
                    bulkDataObject?.version }}
                </p>
                <p>
                    {{ bulkDataObject?.isbn }} | {{ bulkDataObject?.virt_isbn }}
                </p>

                <p> Facultad: {{ bulkDataObject?.parent_name }} </p>
                <p> Escuela: {{ bulkDataObject?.school_name }} </p>

                <p> Usuario del solicitante: {{ bulkDataObject?.requester_name }} </p>

                <p> Email del solicitante: {{ bulkDataObject?.requester_email }} </p>

                <p> Teléfono del solicitante: {{ bulkDataObject?.requester_phone }} </p>

            </div>
        </div>
        <!-- <div class="table-responsive-x">
            <table class="w-100 table-scroll-wrap animated fadeIn" mat-table [dataSource]="shopsDataSource" matSort>
                <ng-container matColumnDef="temporal_id">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Id Temporal
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.temporal_id }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="nombre">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Nombre
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.nombre }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="alias">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Alias
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.alias }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="parent">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Parent
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.parent }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="calle">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Calle
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.calle }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="campus">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Campus
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.campus }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="pais">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Pais
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.pais }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="ciudad">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Ciudad
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.ciudad }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="estado">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Estado
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.estado }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="colonia">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Colonia
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.colonia }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="edificio">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Edificio
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.edificio }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="codigo_postal">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Codigo postal
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.codigo_postal }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="num_ext">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Numero Exterior
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.num_ext }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="num_int">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Numero Interior
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.num_int }}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="telefono">
                    <th class="text-nowrap px-3" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Telefono
                    <td class="text-nowrap px-3" mat-cell *matCellDef="let element">
                        <div>{{ element.telefono }}</div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedShopsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedShopsColumns"></tr>
            </table>
        </div> -->
    </mat-card>


    <br>
    <div *ngIf="!bulkData?.approved" align='right'>
        <button mat-raised-button style="color: cornsilk; background-color: #388e3c; margin-right: 5px;"
            (click)="approveOrReject(bulkData?.id, true)">
            Aprobar Petición
        </button>

        <button mat-raised-button style="color: cornsilk; background-color: #ff4081; margin-left: 5px;"
            (click)="approveOrReject(bulkData?.id, false)">
            Rechazar Petición
        </button>
    </div>
    <br>
</div>

<ng-template #approved>
    <div style="color: darkcyan">
        Aprobada
    </div>
</ng-template>