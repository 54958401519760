import { Component, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface,PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

@Component({
	selector: 'app-side-bar',
	templateUrl: './SideBar.component.html',
	styleUrls: ['./SideBar.component.scss']
})

export class SideBarComponent implements OnInit {

	public config : PerfectScrollbarConfigInterface = {};

	constructor() { }
	activeHelper = false;
	ngOnInit() {
		let user = JSON.parse(localStorage.getItem('identity'));
			if (user.rol == 'asistente_entregas') {
				this.activeHelper = true;
			}
	}

}
	