<button mat-mini-fab class="log-in-user" [matMenuTriggerFor]="loginuser">
  <img src="assets/user/admin.png" width="250" height="250" alt="log-in-user">
</button>
<!-- log-in-user -->
<mat-menu #loginuser="matMenu">
  <button  *ngIf="!activeHelper"mat-menu-item fxLayoutAlign="start center" (click)="navToProfile()"><i class="material-icons mr-1">account_circle</i> <span>Mi cuenta</span>
  </button>
  <button *ngIf="!activeHelper" mat-menu-item [routerLink]="['/admin-panel/account/settings']"><i class="material-icons mr-1">settings</i><span>Ajustes de cuenta</span></button>
  <button mat-menu-item (click)="logout()"><i class="material-icons mr-1">power_settings_new</i><span>Salir</span></button>
</mat-menu>

