export class Help {
  constructor(
    public _id: string,
    public id_user: string,
    public user_name: string,
    public user_email: string,
    public user_notes: string,
    public status: string,
    public domain: string
  ) { }
}