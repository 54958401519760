import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'

import { global } from '../Config/config'

@Injectable()
export class SaleDetailService {
  public url: string
  public identity
  public token

  constructor(private _http: HttpClient) {
    this.url = global.url
  }

  getSaleDetail(values): Observable<any> {
    let params = values
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    // Hacer peticion ajax
    return this._http.get(this.url + 'get-sale-details/' + params, { headers: headers })
  }

  createSaleDetail(values): Observable<any> {
    let params = values
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    // Hacer peticion ajax
    return this._http.post(this.url + 'create-sale-detail/', params, { headers: headers })
  }
}
