import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms'
//SweetAlert
import Swal from 'sweetalert2'

//Load Models
import { User } from '../../Models/user.model'
//Load services
import { UserService } from '../../Services/user.service'
import { ParentService } from '../../Services/parent.service'
import { CartService } from '../../Services/cart.service'
import { CategoryService } from '../../Services/category.service'
import { ShopService } from '../../Services/shop.service'

//Dialogs
import { MatDialog } from '@angular/material/dialog'
import { validationComponent } from './ValidationCodeModal/validation.component'
import { termsAndConditionsComponent } from './TermsAndConditions/termsandconditions.component'

//Extras Menu
import { MenuItems } from "../../Core/menu/menu-items/menu-items";

@Component({
  selector: 'embryo-SignIn',
  templateUrl: './CommonSignIn.component.html',
  styleUrls: ['./CommonSignIn.component.css'],
  providers: [UserService, ParentService, CartService, CategoryService, ShopService],
})
export class CommonSignInComponent implements OnInit {
  _login: FormGroup
  emailPattern: any = /\S+@\S+\.\S+/
  public identity
  public token
  public user: User
  public status: string
  date = new Date()
  public parent
  public domain_id

  //Smart selections
  public smart_selection = JSON.parse(localStorage.getItem('smart_selections'))
  //Flow vars
  public flow = {
    flow_program_name: null,
    flow_type: null,
    flow_request: null,
    active_sale: null,
    domain_name: null,
    id_domain: null,
    id_program: null,
    id_shop: null,
    level: null,
    type: null,
    sale_type: null,
    school_name: null,
  }

  public rol
  constructor(
    private _userService: UserService,
    private _parentService: ParentService,
    private _cartService: CartService,
    private _categoryService: CategoryService,
    private _shopService: ShopService,
    private _router: Router,
    private _route: ActivatedRoute,
    private formGroup: FormBuilder,
    public dialog: MatDialog,
    public menuItems: MenuItems,
  ) {
    //Create user object
    this.user = new User('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '')
  }

  ngOnInit() {
    //Get parent name
    this.parent = JSON.parse(localStorage.getItem('parent'))

    //console.log(this.parent)
    this._parentService.getParentInfo(this.parent).subscribe(
      response => {
        if (response.status == 'success') {
          let parent_data = response.parentFound
          this.domain_id = parent_data.iddominio
          //console.log(this.domain_id)
        }
      },
      error => {
        console.warn(error)
      }
    )

    let parent_info = JSON.parse(localStorage.getItem('parent_info'))
    this.domain_id = parent_info.iddominio

    let token = JSON.parse(localStorage.getItem('token'))
    let user = JSON.parse(localStorage.getItem('identity'))
    let verification = JSON.parse(localStorage.getItem('verification'))
    if (user) {
      if (user.rol == 'comprador') {
        //For normal users verify if user is logged in
        if (token) {
          let parent
          if (this.smart_selection) {
            parent = this.smart_selection.parent_name
          } else {
            parent = JSON.parse(localStorage.getItem('parent'))
          }
          this._router.navigate([parent + '/user_dashboard/profile'])
        }
      }

      if (
        user.rol == 'coordinador' ||
        user.rol == 'coordinador_dominio' ||
        user.rol == 'coordinador_escuela' ||
        user.rol == 'l2a_manager'
      ) {
        console.log(verification)
        if (verification === true) {
          localStorage.removeItem('verification')
          this._router.navigate([this.parent + '/admin-panel/reports'])
        } else {
          //Clean storage and reload
          this.deleteActualLocalStorageData()

          this._router.navigate([this.parent + '/session/signin'])
        }
      }

      if (user.rol == 'master') {
        this._router.navigate([this.parent + '/admin-panel/master'])
      }
    }

    this._login = this.formGroup.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ['', [Validators.required]],
    })
  }

  login() {
    let parent = JSON.parse(localStorage.getItem('parent'))

    let params = this._login.value
    //console.log(params)

    this.user.id_parent = this.domain_id
    //console.log(this.domain_id)

    //console.log(this.user.id_parent)

    this.user.email = params.email
    this.user.password = params.password

    this._userService.login(this.user).subscribe(
      response => {
        this.rol = response.targetUser.rol

        let parent_ruta = response.targetUser.dominio.toLowerCase()
        //console.log(parent)
        //console.log(parent_ruta)

        if (this.parent.toLowerCase() != parent_ruta) {
          this.deleteActualLocalStorageData()
          return Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Tus datos no corresponden al parent, revisa que tu URL este correcta!',
            footer: 'Pista' + parent_ruta,
          }).then(afterWrongParentAcc => {
            if (afterWrongParentAcc) {
              location.reload()
            }
          })
        }
        if (response.targetUser && response.targetUser.idusuario) {
          //console.log(response.targetUser);
          //console.log(response.targetUser.idusuario);


          //Check activated account
          if (response.targetUser.activo == '0') {
            //console.log(response.targetUser.activo)

            return Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Tu cuenta aun no ha sido activada, revisa tu email!',
            }).then(afterAccNotActivated => {
              if (afterAccNotActivated) {
                this.deleteActualLocalStorageData()
                location.reload()
              }
            })
          }
          //Guardar la identidad de mi usuario
          this.identity = response.targetUser
          localStorage.setItem('identity', JSON.stringify(this.identity))

          //Conseguir el token del usuario identificado
          this._userService.login(this.user, true).subscribe(
            response => {
              if (response.token) {
                //Guardar el token del usuario
                this.token = response.token
                localStorage.setItem('token', JSON.stringify(this.token))

                this.status = 'success'

                if ((this.status = 'success')) {
                  this._cartService.loadMyCart(this.identity.idusuario).subscribe(response => {
                    if (response) {
                      //Save in localStorage
                      localStorage.setItem('cart_info', JSON.stringify(response.targetCar))
                      //console.log(response.targetCar)
                    }
                  })
                  //console.log(this.rol);
                  if (this.rol == 'master') {
                    this._router.navigate([parent + '/admin-panel/master'])
                  }
                  //Checking rol for redirection and flow
                  if (this.rol == 'comprador') {
                    //Get the programm and make the settings
                    let id_programm = this.identity.usuario_idprograma
                    //console.log(id_programm);

                    //Damn migration
                    if (id_programm != -1) {
                      //Flow structure starts getting the data of the programm
                      this._categoryService.getCategory(id_programm).subscribe(response => {
                        if (response.status == 'success') {
                          let program_params = response.program
                          //console.log(program_params);


                          //Check active sale
                          this._shopService.getShop(program_params.idescuela).subscribe(responseB => {
                            if (responseB.status == 'success') {
                              let shop_params = responseB.shopTarget

                              //Create flow settings
                              this.flow.flow_program_name = program_params.alias
                              this.flow.flow_type = program_params.flow_type
                              this.flow.flow_request = program_params.flow_request
                              this.flow.active_sale = shop_params.venta_activa
                              this.flow.domain_name = program_params.dominio
                              this.flow.id_domain = program_params.iddominio
                              this.flow.id_program = program_params.idprograma
                              this.flow.id_shop = program_params.idescuela
                              this.flow.level = program_params.nivel
                              this.flow.type = program_params.tipo
                              this.flow.sale_type = program_params.tipo_venta
                              //this.flow.resquest_codes = program_params.requested_codes_qty
                              this.flow.school_name = shop_params.nombre

                              localStorage.setItem('flow', JSON.stringify(this.flow))

                              //Scenario 1 Flow A = Just_Books
                              //Scenario 1 Flow B = Just_Codes
                              //Scenario 1 Flow C = Codes_Agent_Fee
                              //Scenario 3 Flow A = Just_Temporal_&_Book
                              //Scenario 3 Flow B = Just_Code_Extend
                              //Scenario 4 Flow A = Just_Hybrid [Means Code or Book Selection]
                              if (
                                this.flow.flow_request == 'Just_Books' ||
                                this.flow.flow_request == 'Just_Codes' ||
                                this.flow.flow_request == 'Codes_Agent_Fee' ||
                                this.flow.flow_request == 'Just_Code_Extend' ||
                                this.flow.flow_request == 'Just_Temporal_&_Book' ||
                                this.flow.flow_request == 'Just_Hybrid'
                              ) {
                                //->  first purchase give him a temporal code
                                this._router.navigate([parent + '/productos'])
                              }

                              //Scenario 2  Flow A
                              //Target: Codes already sold
                              //Send to: the orders page
                              if (this.flow.flow_request == 'Just_Codes_Prepay') {
                                //-> send to the orders
                                this._router.navigate([parent + '/user_dashboard/order-history/'])
                              }
                            } else {
                              console.log('Server error check')
                            }
                          })
                        } else {
                          console.log('Server error check')
                        }
                      })
                    } else {

                      //Rebuild fake data to leave the user change it and reedirect it to the program selection
                      //When program equals -1

                      //Ignore program data
                      //Ignore school data
                      //Create flow settings
                      this.flow.flow_program_name = 'Migration: Select your program'
                      this.flow.flow_type = 'Migration: Select your program'
                      this.flow.flow_request = 'Migration: Select your program'
                      this.flow.active_sale = 0
                      this.flow.domain_name = parent
                      this.flow.id_domain = this.identity.iddominio
                      this.flow.id_program = this.identity.usuario_idprograma
                      this.flow.id_shop = this.identity.idescuela
                      this.flow.level = 'Migration: level off'
                      this.flow.type = 'Migration: type off'
                      this.flow.sale_type = 'Migration: sale type off'
                      //this.flow.resquest_codes = program_params.requested_codes_qty
                      this.flow.school_name = 'Migration: Select your program'

                      localStorage.setItem('flow', JSON.stringify(this.flow))

                      this._router.navigate([parent + '/user_dashboard/escuela/'])


                    }
                  }

                  if (
                    this.rol == 'coordinador' ||
                    this.rol == 'coordinador_dominio' ||
                    this.rol == 'coordinador_escuela' ||
                    this.rol == 'distribuidor' ||
                    this.rol == 'l2a_manager'
                  ) {
                    //Get the programm and make the settings
                    let id_programm = this.identity.usuario_idprograma
                    //console.log(id_programm)
                    //Flow structure starts getting the data of the programm
                    this._categoryService.getCategory(id_programm).subscribe(response => {
                      if (response.status == 'success') {
                        //console.log(response)

                        let program_params = response.program

                        //Check active sale
                        this._shopService.getShop(program_params.idescuela).subscribe(responseB => {
                          if (responseB.status == 'success') {
                            let shop_params = responseB.shopTarget
                            //console.log(shop_params)

                            //Create flow settings
                            this.flow.flow_program_name = program_params.alias
                            this.flow.flow_type = program_params.flow_type
                            this.flow.flow_request = program_params.flow_request
                            this.flow.active_sale = shop_params.venta_activa
                            this.flow.domain_name = program_params.dominio
                            this.flow.id_domain = program_params.iddominio
                            this.flow.id_program = program_params.idprograma
                            this.flow.id_shop = program_params.idescuela
                            this.flow.level = program_params.nivel
                            this.flow.type = program_params.tipo
                            this.flow.sale_type = program_params.tipo_venta
                            //this.flow.resquest_codes = program_params.requested_codes_qty
                            this.flow.school_name = shop_params.nombre

                            localStorage.setItem('flow', JSON.stringify(this.flow))

                            //Scenario 1 Flow A = Just_Books
                            //Scenario 1 Flow B = Just_Codes
                            //Scenario 1 Flow C = Codes_Agent_Fee
                            //Scenario 2 Flow A = Just_Codes_Prepay
                            //Scenario 3 Flow A = Just_Temporal_&_Book
                            //Scenario 3 Flow B = Just_Code_Extend
                            //Scenario 4 Flow A = Just_Hybrid [Means Code or Book Selection]
                            if (
                              this.flow.flow_request == 'Just_Books' ||
                              this.flow.flow_type == 'Just_Codes' ||
                              this.flow.flow_request == 'Codes_Agent_Fee' ||
                              this.flow.flow_request == 'Just_Codes_Prepay' ||
                              this.flow.flow_request == 'Just_Temporal_&_Book' ||
                              this.flow.flow_request == 'Just_Code_Extend' ||
                              this.flow.flow_request == 'Just_Hybrid'
                            ) {
                              //->  first purchase give him a temporal code
                              this.user.phone = this.identity.telefono
                              let dialogRef = this.dialog.open(validationComponent, {
                                data: { data: this.user },
                              })

                              dialogRef.afterClosed().subscribe(result => {
                                location.reload()
                              })
                            }
                          } else {
                            console.log('Server error check')
                          }
                        }, error => {
                          console.warn('Bad record configuration, programm missing:', error)
                          localStorage.removeItem('token')

                        })
                      } else {
                        console.log('Server error check')
                      }
                    },
                      error => {

                        console.warn('Bad record configuration, programm missing:', error)
                        localStorage.removeItem('token')

                      })
                  }

                  //Rol navigation handler
                  if (this.rol == 'asistente_entregas') {
                    //Open the dialog for the code validation
                    this.user.phone = this.identity.telefono
                    this.dialog.open(validationComponent, {
                      data: { data: this.user },
                    })
                  }
                }
              } else {
                this.status = 'error'
              }
            },
            error => {
              this.status = 'error'
            }
          )
          //Fin conseguir el token del usuario identificado
        } else {
          this.status = 'errorServer'
        }
      },
      error => {

        console.warn('Error de crendeciales:', error);

        this.deleteActualLocalStorageData()

        this.status = 'errorServer'
        Swal.fire(
          'Lo lamentamos!',
          'Ingresa un email y una contraseña valida, verifica que estes accediendo a la facultad correcta!',
          'error'
        ).then(afterAccNotFoundOrErrorInHisData => {
          if (afterAccNotFoundOrErrorInHisData) {
            location.reload()
          }
        })
      }
    )

    //this.router.navigate([parent + '/user_dashboard/profile']);
  }

  openTermsAndConditions() {
    this.dialog.open(termsAndConditionsComponent, {
      data: { data: this.user },
    })
  }

  navigateToSignup() {
    let parent = JSON.parse(localStorage.getItem('parent'))

    this._router.navigate([parent + '/session/signup'])
  }

  deleteActualLocalStorageData() {
    //Clean storage and reload
    localStorage.removeItem('identity')
    localStorage.removeItem('token')
    localStorage.removeItem('current_user')
    localStorage.removeItem('cart')
    localStorage.removeItem('cart_info')
    localStorage.removeItem('coordinator_selections')
    localStorage.removeItem('flow')
  }
}
