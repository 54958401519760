import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { global } from "../Config/config";

@Injectable()
export class TemplateService {
    public url: string;
    public identity;
    public token;

    constructor(private _http: HttpClient) {
        this.url = global.url;
    }

    getLogos(): Observable<any> {
        //Definir cabeceras
        let headers = new HttpHeaders().set(
            "Content-Type",
            "application/json; charset=utf-8"
        );

        // Hacer peticion ajax
        return this._http.get(this.url + "get-logos", {
            headers: headers,
        });
    }

    updateLogos(params): Observable<any> {
        //Definir cabeceras
        let headers = new HttpHeaders().set(
            "Content-Type",
            "application/json; charset=utf-8"
        );

        // Hacer peticion ajax
        return this._http.post(this.url + "update-logos", params, {
            headers: headers,
        });
    }
}
