import { Component, OnInit } from '@angular/core';

import { TemplateService } from "../../Services/template.service";
@Component({
  selector: 'embryo-AppLogo',
  templateUrl: './AppLogo.component.html',
  styleUrls: ['./AppLogo.component.scss'],
  providers: [TemplateService]
})
export class AppLogoComponent implements OnInit {
  parent;
  main
  sec

  logos = JSON.parse(localStorage.getItem('logos')) ? JSON.parse(localStorage.getItem('logos')) : undefined

  constructor(private _templateService: TemplateService) { }

  ngOnInit() {
    this.parent = JSON.parse(localStorage.getItem('parent'));
    if (!this.logos) {

      this._templateService.getLogos().subscribe(res => {
        //console.log(res);
        this.main = res.result.logo_principal
        this.sec = res.result.logo_principal_movil
        localStorage.setItem('logos', JSON.stringify(res.result))
      })
    } else {
      let x = JSON.parse(localStorage.getItem('logos'))

      this.main = x.logo_principal
      this.sec = x.logo_principal_movil
    }


  }

}
