import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { global } from "../Config/config";

@Injectable()
export class ParentService {
  public url: string;
  public identity;
  public token;

  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  prueba() {
    return "Hola mundo desde el servicio de angular";
  }

  getParentInfo(parent): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    // Hacer peticion https
    return this._http.get(this.url + "/get-parent/" + parent, {
      headers: headers,
    });
  }

  getParentS(): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    // Hacer peticion https
    return this._http.get(this.url + "/get-parents/", { headers: headers });
  }

  createParent(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    // Hacer peticion https
    return this._http.post(this.url + "/create-parent", params, { headers: headers });
  }

  updateParent(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    // Hacer peticion https
    return this._http.put(this.url + "/update-parent-crud-colle", params, { headers: headers });
  }

  removeParent(id): Observable<any> {

    let headers = new HttpHeaders().set("Content-Type", "application/json");

    // Hacer peticion https
    return this._http.delete(this.url + "/delete-parent/" + id, { headers: headers });
  }
}
