<div class="section-gap overlay-section pb-0">
    <div class="container">
      <div fxLayout="row wrap" class="col-gap feature-section-v2">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="33" fxFlex.lg="33" fxFlex.xl="33">
          <mat-card>
            <div class="feature-section-v2-list" fxLayoutAlign="space-between">
              <button mat-fab color="primary"><i class="material-icons">
                  shopping_cart
                </i></button>
              <div class="ml-4 feature-section-v2-content">
                <h4 class="mb-2 font-bold">Free Shipping</h4>
                <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              </div>
            </div>
          </mat-card>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="33" fxFlex.lg="33" fxFlex.xl="33">
          <mat-card>
            <div class="feature-section-v2-list" fxLayoutAlign="space-between">
              <button mat-fab color="primary"><i class="material-icons">
                  thumb_up
                </i></button>
              <div class="ml-4 feature-section-v2-content">
                <h4 class="mb-2 font-bold">24X7 Support</h4>
                <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              </div>
            </div>
          </mat-card>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="33" fxFlex.lg="33" fxFlex.xl="33">
          <mat-card>
            <div class="feature-section-v2-list" fxLayoutAlign="space-between">
              <button mat-fab color="primary"><i class="material-icons">
                  whatshot
                </i></button>
              <div class="ml-4 feature-section-v2-content">
                <h4 class="mb-2 font-bold">Best Price</h4>
                <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>