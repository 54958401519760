

  <embryo-HomePageTwoSlider [isRTL]="embryoService.isDirectionRtl"></embryo-HomePageTwoSlider>
  
  <embryo-CtaGroup></embryo-CtaGroup>

  <embryo-LighteningDeals *ngIf="lighteningDealsProducts" [lighteningDeals]="lighteningDealsProducts"></embryo-LighteningDeals>

  <embryo-TopProducts *ngIf="topProducts" [products]="topProducts" [currency]="embryoService?.currency" (addToCart)="addToCart($event)"></embryo-TopProducts>
   <div class="section-gap pt-0">
   <div class="container">
      <div fxLayoutAlign="space-between" class="w-100">
         <div class="block-title mb-4">
         <h3>Top Brands</h3>
         </div>
      </div>
      <embryo-BrandsLogo [isRTL]="embryoService.isDirectionRtl"></embryo-BrandsLogo>
   </div>
   </div>
   <div class="section-gap pb-0">   
  <embryo-SubscribeTwo></embryo-SubscribeTwo>
   
</div>