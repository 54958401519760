import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'embryo-SubscribeOne',
  templateUrl: './SubscribeOne.component.html',
  styleUrls: ['./SubscribeOne.component.scss']
})
export class SubscribeOneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
