<!-- User & favorites items favicon -->
<button *ngIf="user" mat-mini-fab class="log-in-user" [matMenuTriggerFor]="loginuser">
  <img src="assets/user/avatar.png" width="250" height="250" alt="log-in-user">
</button>
<mat-menu #loginuser="matMenu">
  <!-- <button mat-menu-item fxLayoutAlign="start center" (click)="navToProfile()" ><i class="material-icons mr-1">account_circle</i> <span>Mi perfil</span>
  </button> -->
  <button mat-menu-item (click)="navToProfile()"><i class="material-icons mr-1">settings</i><span>Mi cuenta</span></button>
  <button mat-menu-item (click)="logout()"><i class="material-icons mr-1">power_settings_new</i><span>Salir</span></button>
</mat-menu>
<!-- User & favorites items favicon -->
