import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
//SweetAlert
import Swal from "sweetalert2";

//Model
import { Help } from "../../Models/help.model";
//Services
import { HelpService } from "../../Services/help.service";


@Component({
   selector: 'embryo-ContactForm',
   templateUrl: './ContactForm.component.html',
   styleUrls: ['./ContactForm.component.scss'],
   providers: [HelpService]
})
export class ContactFormComponent implements OnInit {

   contactForm: FormGroup;
   emailPattern: any = /\S+@\S+\.\S+/;

   help_request: Help;

   target_user = JSON.parse(localStorage.getItem('current_user'))

   constructor(private formGroup: FormBuilder,
      public _helpService: HelpService) {

      this.help_request = new Help('', '', '', '', '', '', '');

   }

   ngOnInit() {

      if (this.target_user) {
         const name = this.target_user.nombre;
         const surnames = [this.target_user.apellido_paterno, this.target_user.apellido_materno].join(" ")
         const email = this.target_user.email

         this.contactForm = this.formGroup.group({
            first_name: [name, { validators: [Validators.required] }],
            last_name: [surnames, { validators: [Validators.required] }],
            email: [email, { validators: [Validators.required, Validators.pattern(this.emailPattern)] }],
            message: ['', { validators: [Validators.required] }]
         })

      } else {
         this.contactForm = this.formGroup.group({
            first_name: ['', { validators: [Validators.required] }],
            last_name: ['', { validators: [Validators.required] }],
            email: ['', { validators: [Validators.required, Validators.pattern(this.emailPattern)] }],
            message: ['', { validators: [Validators.required] }]
         })
      }




   }

   public sendMessage() {


      if (this.contactForm.valid) {
         let params = this.contactForm.value;
         let fullname = params.first_name + ' ' + params.last_name;
         let usuario = JSON.parse(localStorage.getItem('current_user'));

         this.help_request.id_user = usuario ? usuario.idusuario : 0;
         this.help_request.user_name = fullname;
         this.help_request.user_email = params.email;
         this.help_request.user_notes = params.message;
         this.help_request.status = '1';
         this.help_request.domain = JSON.parse(localStorage.getItem('parent'))

         this._helpService.sendMessage(this.help_request).subscribe(response => {

            if (response.status == 'success') {

               Swal.fire(
                  'Listo!',
                  'Tu mensaje ha sido enviado, nos comunicaremos contigo pronto!',
                  'success'
               )

            } else {
               Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Error del servidor, intenta más tarde!'
               })
            }

         })

      } else {

         for (let i in this.contactForm.controls) {
            this.contactForm.controls[i].markAsTouched();
         }

      }
   }

}
