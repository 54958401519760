import { Component, OnInit, HostBinding, Input } from '@angular/core'
import { Router } from '@angular/router'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { TranslateService } from '@ngx-translate/core'

import { MenuItems } from '../../../Core/menu/menu-items/menu-items'

@Component({
  selector: 'embryo-Menu',
  templateUrl: './Menu.component.html',
  styleUrls: ['./Menu.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.,0.0,0.2,1)')),
    ]),
  ],
})
export class MenuComponent implements OnInit {
  expanded: boolean
  cordi_items
  user_items
  constructor(public menuItems: MenuItems, public router: Router, public translate: TranslateService) { }

  ngOnInit() {

    let user = JSON.parse(localStorage.getItem('identity'))

    //Creating menu depending the user
    if (user) {
      if (user.rol == 'comprador') {
        ////SET THIS FUNCTIONALITIE IN THE LOGINSSSSSSS GOGOGOGOGOGO
        this.user_items = this.menuItems.getMainMenu()
      } else {
        this.cordi_items = this.menuItems.getMainMenuCordi()
      }
    }
  }

  public onItemSelected(item: any) {
    if (item.children && item.children.length) {
      this.expanded = !this.expanded
    }
  }

  public redirectTo(subchildState) {
    this.router.navigate([subchildState.state], { queryParams: { category: subchildState.queryState } })
  }
}
