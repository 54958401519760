export class Sale{
  constructor(
    public iddominio: string,
    public usuario_idescuela: string,
    public usuario_idprograma: string,
    public idcarrito: string,
    public idusuario: string,
    public modo: string,
    public tipo: string,
    public tipo_rol: string,
    public pago_total: string,
    public pago_concepto: string,
    public entrega_escuela: string,
    public entrega_campus: string,
    public entrega_calle: string,
    public entrega_ciudad: string,
    public entrega_estado: string,
    public entrega_codigo_postal: string,
    public entrega_en: any,
    public entrega_a: any,
    public comprador_nombre: string,
    public comprador_correo_electronico: string,
    public comprador_matricula: string,
    public comprador_telefono: string,
    public total_articulos: any,
    public envio: any,
    public total: any,
    public subtotal: any,
    public estatus_pago: any,
    public estatus_pago_leyenda: string,
    public estatus_entrega_leyenda: string
  ){}
}
