import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { global } from "../Config/config";

@Injectable()
export class ShopService {
  public url: string;
  public identity;
  public googleIdentity;
  public token;

  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  prueba() {
    return "Hola mundo desde el servicio de angular";
  }

  createNewShop(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.post(this.url + "create-shop/", params, { headers: headers });
  }

  updateShop(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.put(this.url + "update-shop/", params, { headers: headers });
  }

  listShopsByParent(parent): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.get(this.url + "list-shops/" + parent, {
      headers: headers,
    });
  }

  getShop(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.get(this.url + "get-shop/" + id, { headers: headers });
  }

  getAllTheShops(): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.get(this.url + "get-all-the-shops/", { headers: headers });
  }

  updateShopState(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.put(this.url + "update-shop-state/" + id, { headers: headers });
  }

  getAllTheShopsInBulkFromDrive(): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    )

    // Hacer peticion ajax
    return this._http.get(this.url + "get-all-the-shops-in-bulk-from-drive/", { headers: headers });
  }

  createNewOrderOfShops(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.post(this.url + 'create-shop-request/', params, { headers: headers });
  }

  getShopRequest(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    )

    // Hacer peticion ajax
    return this._http.get(this.url + "get-shop-request/" + id, { headers: headers });
  }

  createShopsInBulk(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    )

    // Hacer peticion ajax
    return this._http.post(this.url + "create-shops-in-bulk/", params, { headers: headers });
  }

  getShopsBalanceAndForecast(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    )

    // Hacer peticion ajax
    return this._http.get(this.url + "get-shops-balance-and-forecast/" + id, { headers: headers });
  }


  getShopsByCategory(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    )

    // Hacer peticion ajax
    return this._http.get(this.url + "get-shops-by-category/" + id, { headers: headers });
  }

}
