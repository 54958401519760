import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params }   from '@angular/router';

import { CartService } from "../../Services/cart.service";

@Component({
  selector: 'app-ConfirmationPopup',
  templateUrl: './ConfirmationPopup.component.html',
  styleUrls: ['./ConfirmationPopup.component.scss'],
  providers: [ CartService ]
})
export class ConfirmationPopupComponent implements OnInit {

   message : string;
   id      : string;

   constructor(public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
               private route: ActivatedRoute,
               private router: Router,
               private _cartService: CartService) { }

   ngOnInit() {



   }

   delete(){

     //Delete element service
     this._cartService.deleteCartDetail(this.id).subscribe(
       response => {
         //Check kind of response
         if (response.status = 'success') {

           this.dialogRef.close();

           let parent = JSON.parse(localStorage.getItem('parent'));

           //Regresshing the route
           this.router.navigateByUrl(parent + '/productos', { skipLocationChange: true }).then(() => {
              this.router.navigate([parent + '/carrito']);
           });

         }else{
           console.log('Cart service error');
         }
       });


   }

}
