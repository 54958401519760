<div fxLayout='row wrap'>
<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="33.33" fxFlex.xl="33.33">
  <a [routerLink]="['/products/women']">
    <div class="overlay-wrap">
      <a class="p-link" href="javascript:void(0)">
        <img class="w-100" src="assets/images/sale-1.jpg" width="640" height="430" alt="Sale Call In Action">
      </a>
      <div class="overlay-content primary-rgba">
        <div>
          <h2>Sale</h2>
          <h5>Women's Collection</h5>
          <h3>50% Off</h3>
        </div>
      </div>
    </div>
  </a>
</div>
<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="33.33" fxFlex.xl="33.33">
  <a [routerLink]="['/products']">
    <div class="overlay-wrap">
        <a class="p-link" href="javascript:void(0);">
      <img class="w-100" src="assets/images/sale-2.jpg" width="640" height="430" alt="Sale Call In Action">
      </a>
      <div class="overlay-content black-rgba">
        <div>
          <h6>New Arrival</h6>
          <h2>Flat 50</h2>
          <h5>Discount</h5>
        </div>
      </div>
    </div>
  </a>
</div>
<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="33.33" fxFlex.xl="33.33">
  <a [routerLink]="['/products/men']">
    <div class="overlay-wrap">
      <a class="p-link" href="javascript:void(0)">
        <img class="w-100" src="assets/images/sale-3.jpg" width="640" height="430" alt="Sale Call In Action">
      </a>
      <div class="overlay-content pink-rgba">
        <div>
          <h2>Sale</h2>
          <h5>Men's Collection</h5>
          <h3>50% Off</h3>
        </div>
      </div>
    </div>
  </a>
</div>
</div>