import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'embryo-Sales',
  templateUrl: './Sales.component.html',
  styleUrls: ['./Sales.component.scss']
})
export class SalesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
