import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { global } from "../Config/config";

@Injectable()
export class SaleService {
  public url: string;
  public identity;
  public token;

  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  getSale(values): Observable<any> {
    let params = values;
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    // Hacer peticion ajax
    return this._http.get(this.url + "/get-sale/" + params, {
      headers: headers,
    });
  }

  getSales(year?): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    // Hacer peticion ajax
    return this._http.get(this.url + "/get-sales/" + year, {
      headers: headers,
    });
  }

  getSalesByUser(user_id): Observable<any> {
    let id = user_id;
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    // Hacer peticion ajax
    return this._http.get(this.url + "get-unique-user-sales/" + id, {
      headers: headers,
    });
  }

  getSalesTypeCodeBySchool(school_id, programId): Observable<any> {
    let id = school_id;
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    return this._http.get(
      this.url + "get-sales-type-code/" + id + "/" + programId,
      {
        headers: headers,
      }
    );
  }

  getSalesForDelivery(values): Observable<any> {
    let params = values;
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    // Hacer peticion ajax
    return this._http.post(this.url + "get-sales-for-delivery/", params, {
      headers: headers,
    });
  }

  createSale(values): Observable<any> {
    let params = values;
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    // Hacer peticion ajax
    return this._http.post(this.url + "/create-sale/", params, {
      headers: headers,
    });
  }

  confirmDelivery(idsale): Observable<any> {
    let id = idsale;
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    // Hacer peticion ajax
    return this._http.post(this.url + "/update-sale-on-delivery/" + id, {
      headers: headers,
    });
  }

  getSalesInAParent(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    // Hacer peticion ajax
    return this._http.get(this.url + "get-total-sales-parent/" + id, {
      headers: headers,
    });
  }

  getSalesInASchool(id, programId): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    return this._http.get(
      this.url + "get-total-sales-school/" + id + "/" + programId,
      {
        headers: headers,
      }
    );
  }

  getSalesTypeCodeAndPayed(): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    return this._http.get(this.url + "get-sales-type-code-and-payed/", {
      headers: headers,
    });
  }

  getSaleByPaymentId(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    return this._http.get(this.url + "get-sale-by-payment-id/" + id, {
      headers: headers,
    });
  }

  getAllThePayedSales(body): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    return this._http.post(this.url + "get-all-payed-sales", body, {
      headers: headers,
    });
  }
}
