import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

//Services
import { OrderService } from "src/app/Services/order.service";
import { SnackbarService } from "src/app/Services/snackbar.service";

@Component({
  selector: 'app-order-request',
  templateUrl: './order-request.component.html',
  styleUrls: ['./order-request.component.css'],
  providers: [OrderService, SnackbarService]
})
export class OrderRequestComponent implements OnInit {

  constructor(private actRoute: ActivatedRoute,
    private _orderService: OrderService,
    private _snackbarService: SnackbarService) { }
  bulkData: any = null
  bulkDataObject

  ngOnInit() {

    this.actRoute.paramMap.subscribe(params => {
      let id = params.get('id')
      //console.log(id);

      this._orderService.getOrder(id).subscribe(r => {
        if (r.status == 'success') {

          this.bulkData = r.order
          //console.log(this.bulkData);
          this.bulkDataObject = JSON.parse(this.bulkData.purpose)
          //console.log(this.bulkDataObject);

        }
      })

    })
  }

  approveOrReject(id, obs) {
    if (obs) {
      //console.log(id);
      this._orderService.updateOrder(id).subscribe(r => {
        //console.log(r);
        if (r.status == 'success') {
          // console.log(r);
          if (r.res == 1) {
            this._snackbarService.openSnackBar('Orden aprobada, se enviaran los códigos en unos momentos.', 'ok')
          } else {
            this._snackbarService.openSnackBar('Orden rechazada, se notificará al usuario.', 'ok')
          }

          setTimeout(() => {
            window.location.reload()

          }, 3000);
        }
      })
    } else {
      this._snackbarService.openSnackBar('Orden rechazada, se notificará al usuario.', 'ok')
    }

  }

}
