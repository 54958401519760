import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'component-closed-shop',
  templateUrl: './closedShop.component.html',
  styleUrls: ['./closedShop.component.css'],
})
export class ClosedShopComponent implements OnInit {
  public parent = JSON.parse(localStorage.getItem('parent'))
  constructor() {}

  ngOnInit() {
    //console.log(this.parent);
  }
}
