import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { global } from "../Config/config";

@Injectable()
export class HelpService {
  public url: string;

  constructor(private _http: HttpClient) {
    this.url = global.url;
   }

  sendMessage(values): Observable<any>{

    let params = JSON.stringify(values); 
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    // Hacer peticion ajax
    return this._http.post(this.url+'send-help-message/', params , {headers: headers});
  }

}