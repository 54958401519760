export class SaleDetail{
  constructor(
    public idventa: string,
    public idprograma: string,
    public idarticulo: string,
    public isbn: string,
    public serie: string,
    public articulo_nivel: string,
    public articulo_nombre: string,
    public articulo_descripcion: string,
    public cantidad: string,
    public precio: any,
    public precio_descuento: any,
    public precio_total: any
  ){}
}
