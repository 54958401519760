<div fxLayout="row wrap" fxLayoutAlign="space-between">
  <div fxLayout.xs="100" fxLayout.sm="100" fxLayout.md="50" fxLayout.lg="50" fxLayout.xl="50">
    <div>
      <h3>Subscribe our Newsletter</h3>
      <p>Stay up to date with our latest new and products</p>
    </div>
  </div>
  <div fxLayout.xs="100" fxLayout.sm="100" fxLayout.md="50" fxLayout.lg="50" fxLayout.xl="50">
    <form class="example-form">
      <div fxLayoutAlign="start center">
        <mat-form-field class="w-100">
          <input matInput placeholder="Your Email Address">
        </mat-form-field>
        <button mat-icon-button>
          <i class="material-icons">send</i>
        </button>
      </div>
    </form>
  </div>
</div>
