import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'terms-condition-modal',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.css'],
})
export class termsAndConditionsComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<termsAndConditionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}
}
