import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { log } from "util";

@Injectable()
export class CoordinatorGuard implements CanActivate {
  constructor(private _router: Router) {}

  canActivate() {
    //Rol check
    let identity = JSON.parse(localStorage.getItem("identity"));

    if (identity.rol == "asistente_entregas" || identity.rol == "comprador") {
      console.log("guard coordi");

      //From here i can send the user a dialog to register into our system
      let parent = JSON.parse(localStorage.getItem("parent"));

      if (identity.rol == "asistente_entregas") {
        this._router.navigate([parent + "/admin-panel/deliveries"]);
      }

      return false;
    }

    if (
      identity.rol == "coordinador" ||
      identity.rol == "coordinador_dominio" ||
      identity.rol == "coordinador_escuela" ||
      identity.rol == "l2a_manager"
    ) {
      //Grant access
      return true;
    }
  }
}
